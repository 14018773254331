<template>
  <div>
    <v-card>
      <v-card-title> Zones </v-card-title>
      <v-data-table data-cy="zones" :items="zones" :headers="headers" :items-per-page="30" sort-by="zoneName"
        :search="search" @click:row="selectItem">
        <template v-slot:top>
          <v-toolbar>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn data-cy="addZone" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  Add Zone
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span v-if="!editZone" class="text-h5">Add Zone</span>
                  <span v-if="editZone" class="text-h5">Edit Zone</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="zoneName" v-model="editedItem.zoneName" label="Zone name"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="zoneUsage" v-model="editedItem.zoneUsage" label="Usage"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field data-cy="zoneSize" v-model="editedItem.zoneSize" label="Size in m2"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea data-cy="zoneDescription" v-model="editedItem.zoneDescription" label="Description"
                          auto-grow></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn data-cy="addZoneClose" @click="close"> Cancel </v-btn>
                  <v-btn data-cy="addZoneSave" color="primary" @click="save(editedItem)">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click.stop.prevent="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon @click.stop.prevent="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon large v-bind="attrs" v-on="on" :class="{ 'negative-sign': item.managementPaused }"
                @click.stop="toggleManagement(item)">mdi-ship-wheel</v-icon>
            </template>
            <span>Has been seeded</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// #42 https://vuetifyjs.com/en/components/data-tables/#server-side-paginate-and-sort

export default {
  name: 'ZonesHome',
  components: {},

  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedItem: { name: '', usage: '' },
    editedIndex: -1,
    editZone: false,
    search: '',
    headers: [
      { text: 'Name', align: 'start', value: 'zoneName' },
      { text: 'Description', align: 'start', value: 'zoneDescription' },
      { text: 'Usage', align: 'start', value: 'zoneUsage' },
      { text: 'Size in sqm', align: 'start', value: 'zoneSize' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    zones: []
  }),

  mounted: function () {
    this.loadZones()
  },

  methods: {
    loadZones () {
      fetch('/api/zones', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    },

    close () {
      this.dialog = false
    },

    save (item) {
      const url = this.editZone ? `/api/zones/${item.id}` : '/api/zones'

      fetch(url, {
        method: this.editZone ? 'PUT' : 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(item)
      })
        .then(() => {
          this.loadZones()
          this.dialog = false
        })
        .catch((error) => console.error('Save item: /api/zones failed', error))
    },

    closeDelete () {
      this.dialogDelete = false
    },

    deleteItemConfirm () {
      this.dialogDelete = false
      fetch(`/api/zones/${this.editedItem.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(() => {
          this.loadZones()
        })
    },

    editItem (item) {
      this.editedIndex = this.zones.indexOf(item)
      this.editedItem = Object.assign({}, item)

      delete this.editedItem.grazingSessions

      this.editZone = true
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.zones.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    selectItem (item) {
      alert(item.name)
    },

    toggleManagement (item) {
      item.managementPaused = !item.managementPaused

      fetch(`/api/zones/${item.id}/toggle-management`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(() => {
          this.loadZones()
        })
    }
  }
}
</script>
